// plugins/validation/index.js
import {getCurrentInstance, reactive, toRaw} from 'vue'
import {z} from "zod";

/**
 * Holds the state of all forms
 * @type {Object<string, Object>}
 */
const formStates = reactive({})

/**
 * Create or get existing form state
 * @param {string} formName - The name of the form
 * @returns {Object} The form state object
 */
const createFormState = (formName) => {
    if (!formName) {
        throw new Error("Form name is required");
    }
    if (!formStates[formName]) {
        formStates[formName] = reactive({
            values: {},
            errors: {},
            touched: {},
            schema: null,
            onSubmit: null,
            isValid: false
        })
    }
    return formStates[formName]
}

/**
 * Main validation directive
 */
export const vField = {
    /**
     * Called when the directive is mounted
     * @param {HTMLElement} el - The element the directive is bound to
     * @param {Object} binding - An object containing the properties of the directive
     * @param {Object} vnode - The virtual node produced by Vue's compiler
     */
    mounted(el, binding, vnode) {
        let {formName, field, schema} = typeof binding.value === 'object' ? binding.value : {};
        if (typeof binding.value === 'string') {
            field = binding.value;
        }
        formName = formName || binding.instance.$options.__name;
        const form = createFormState(formName)

        if (!form.schema && schema) {
            form.schema = schema
        }

        setFieldValue(form.values, field, el.value || '');

        // Handle blur event
        el.addEventListener('blur', () => {
            form.touched[field] = true
            validateField(formName, field)
        })

        // Modified input handler
        el.addEventListener('input', (event) => {
            setFieldValue(form.values, field, event.target.value);
            // Always validate on input to clear errors when valid
            validateField(formName, field)
        })
    },
    /**
     * Called when the element containing the directive is updated
     * @param {HTMLElement} el - The element the directive is bound to
     * @param {Object} binding - An object containing the properties of the directive
     */
    updated(el, binding) {
        let {formName, field, schema} = typeof binding.value === 'object' ? binding.value : {};
        if (typeof binding.value === 'string') {
            field = binding.value; // Set field to the string value
        }
        formName = formName || binding.instance.$options.__name;
        const form = formStates[formName]
        form.values[field] = el.value
    }
}

/**
 * Form directive to handle form-level validation
 */
export const vForm = {
    /**
     * Called when the directive is mounted
     * @param {HTMLElement} el - The element the directive is bound to
     * @param {Object} binding - An object containing the properties of the directive
     */
    mounted(el, binding) {
        let formName = null;
        if (binding.value) {
            formName = binding.value.name;
        }
        if (!formName) {
            formName = binding.instance.$options.__name;
        }
        const form = createFormState(formName)

        el.addEventListener('submit', async (event) => {
            event.preventDefault();
            if (await validateForm(formName)) {
                const onSubmit = binding.value?.onSubmit || form.onSubmit;
                if (onSubmit) {
                    onSubmit.constructor.name === 'AsyncFunction' ? await onSubmit(toRaw(form.values)) : onSubmit(toRaw(form.values));
                }
            }
        })
    }
}

/**
 * Validate a single field
 * @param {string} formName - The name of the form
 * @param {string} field - The field to validate
 */
const validateField = (formName, field) => {
    const form = formStates[formName]
    const fieldSchema = getFieldSchema(form.schema, field)
    const fieldValue = getFieldValue(form.values, field)

    try {
        // Only validate if there's a value or the field has been touched
        if (fieldValue || form.touched[field]) {
            fieldSchema.parse(fieldValue);
        }
        // Clear error when valid
        delete form.errors[field]
        return true
    } catch (error) {
        form.errors[field] = error?.errors?.[0].message
        return false
    }
}

/**
 * Validate the entire form
 * @param {string} formName - The name of the form
 * @returns {boolean} True if the form is valid, otherwise false
 */
const validateForm = (formName) => new Promise(async (resolve) => {
    const form = formStates[formName]
    try {
        await form.schema.parseAsync(form.values)
        form.errors = {}
        form.isValid = true
        resolve(true);
    } catch (error) {
        if (error.errors) {
            form.errors = error.errors.reduce((acc, err) => {
                const path = err.path.join('.');
                acc[path] = err.message;
                return acc;
            }, {});
        }
        console.log('Validation errors:', form.errors);
        form.isValid = false
        resolve(false);
    }
});

/**
 * Get nested schema for a field
 * @param {Object} schema - The schema object
 * @param {string} field - The field name
 * @returns {Object} The schema for the specified field
 */
const getFieldSchema = (schema, field) => field.split('.').reduce((acc, part) => acc.shape?.[part] || acc, schema);

/**
 * Retrieves the value of a nested field from an object based on a dot-separated field path.
 *
 * @param {Object} values - The object from which to retrieve the field value.
 * @param {string} field - The dot-separated path to the target field.
 * @returns {*} The value of the specified field within the object.
 */
const getFieldValue = (values, field) => field.split('.').reduce((acc, part) => acc[part], values);

/**
 * Sets a value to a specific field in a nested object structure.
 *
 * @param {Object} values - The base object to update.
 * @param {string} field - Dot-separated string representing the nested field to update (e.g., "user.address.street").
 * @param {*} value - The value to set at the specified field.
 * @returns {Object} The updated object with the new value set at the specified field.
 */
const setFieldValue = (values, field, value) => field.split('.').reduce((acc, part, index, arr) => {
    if (index === arr.length - 1) {
        acc[part] = value;
    } else {
        acc[part] = acc[part] || {};
    }
    return acc[part];
}, values);

/**
 * Custom hook for handling form validation.
 *
 * @param {Object} options - The options for form validation.
 * @param {string} options.name - The name of the form.
 * @param {Object} options.values - The initial values of the form fields.
 * @param {Object} options.schema - The schema for form validation.
 * @param {Function} options.onSubmit - The callback function to be called on form submit.
 * @return {Object} An object containing form state and handlers.
 */
export function useFormValidation({name, values, schema, onSubmit}) {
    if (!name) {
        const instance = getCurrentInstance()
        name = instance.proxy.$options.__name
    }
    const form = createFormState(name)
    
    if (values) {
        form.values = reactive(values)
    }
    if (schema) {
        form.schema = schema
    }
    if (onSubmit) {
        form.onSubmit = onSubmit
    }

    return {
        values: form.values,
        errors: form.errors,
        touched: form.touched,
        isValid: form.isValid,
        reset: () => {
            form.values = {}
            form.errors = {}
            form.touched = {}
            form.isValid = false
        },
        setValues: (values) => {
            form.values = values
        },
        setSchema: (schema) => {
            form.schema = schema
        },
        validate: () => validateForm(name)
    }
}

/**
 * Plugin installation
 */
export const ValidationPlugin = {
    /**
     * Install the validation plugin
     * @param {Object} app - The Vue app instance
     */
    install(app) {
        app.directive('field', vField)
        app.directive('form', vForm)
    }
}

export const ValidateSchema = {
    email: z.string().email({message: "Invalid email address"}),
    password: z.string().min(8, "Password must contain at least 8 character(s)").max(120).refine(value => {
        return /[a-z]/.test(value) && /[A-Z]/.test(value) && /\d/.test(value) && /[@#$%&]/.test(value);
    }, {
        message: "Password must have at least one uppercase letter, one lowercase letter, one number, and one special character (@#$%&)."
    }),
}